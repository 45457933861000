import { useContext, useEffect } from "react";
import { LoadingContext } from "../../context/LoadingContext";
import { useLocation, useNavigate } from "react-router-dom";
import routeNames from "../../constants/routeNames";

const LinkExpired = () => {
  const { setLoading } = useContext(LoadingContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location?.state?.redirected) {
      navigate(routeNames.LANDING);
    }
    setLoading(false);
  }, []);
  return (
    <div>
      <h1 className="page-title">Sorry</h1>
      <p className="description mb-6">Your link has expired.</p>
      <p className="description !font-normal">
        Please contact customer services at{" "}
        <a
          className="underline"
          href="mailto:heinekencampaigns@meshmarketing.co.uk"
        >
          heinekencampaigns@meshmarketing.co.uk
        </a>{" "}
        for more details.
      </p>
    </div>
  );
};

export default LinkExpired;
