import { createBrowserRouter, redirect } from "react-router-dom";
import routeNames from "../constants/routeNames";
import AgeGateRoute from "../components/AgeGateRoute";
import AgeGate from "../containers/AgeGate/AgeGate";
import Landing from "../containers/Landing/Landing";
import ChooseVenue from "../containers/Signup/ChooseVenue";
import SignupForm from "../containers/Signup/SignupForm";
import Closed from "../containers/Simple/Closed";
import AlreadyEntered from "../containers/Simple/AlreadyEntered";
import NotWinner from "../containers/Simple/NotWinner";
import OutOfHours from "../containers/Simple/OutOfHours";
import PreEntry from "../containers/Simple/PreEntry";
import GenericTerms from "../containers/Simple/Terms/GenericTerms";
import StarPubsTerms from "../containers/Simple/Terms/StarPubs";
import GreeneKingTerms from "../containers/Simple/Terms/GreeneKing";
import StoneGateTerms from "../containers/Simple/Terms/StoneGate";
import CookiePolicy from "../containers/Simple/CookiePolicy";
import SignupSuccess from "../containers/Simple/SignupSuccess";
import Scan from "../containers/Scan/Scan";
import InstantWin from "../containers/InstantWin/InstantWin";
import AlreadyClaimed from "../containers/Simple/AlreadyClaimed";
import LinkExpired from "../containers/Simple/LinkExpired";
import Claimed from "../containers/Simple/Claimed";
import ClaimPrize from "../containers/ClaimPrize/ClaimPrize";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AgeGateRoute />,
    children: [
      {
        index: true,
        element: <Landing />,
        name: "Home",
      },

      {
        path: routeNames.CLOSED,
        element: <Closed />,
        name: "Closed",
      },
      {
        path: routeNames.SUCCESS,
        element: <SignupSuccess />,
        name: "Signup Success",
      },
      {
        path: routeNames.ALREADY_ENTERED,
        element: <AlreadyEntered />,
        name: "Already entered",
      },
      {
        path: routeNames.NOT_WINNER,
        element: <NotWinner />,
        name: "Not Winner",
      },
      {
        path: routeNames.OUTHOURS,
        element: <OutOfHours />,
        name: "Out of Hours",
      },
      {
        path: routeNames.ALREADY_CLAIMED,
        element: <AlreadyClaimed />,
        name: "Already Claimed",
      },
      {
        path: routeNames.LINK_EXPIRED,
        element: <LinkExpired />,
        name: "Link Expired",
      },
      {
        path: routeNames.CLAIM_PRIZE,
        element: <ClaimPrize />,
        name: "Claim your prize",
      },
      {
        path: routeNames.CLAIMED,
        element: <Claimed />,
        name: "Prize Claimed",
      },
      {
        path: routeNames.PREENTRY,
        element: <PreEntry />,
        name: "Pre Entry",
      },
      {
        path: routeNames.SCAN,
        element: <Scan />,
        name: "Scan",
      },
      {
        path: routeNames.INSTANT_WIN_SCAN,
        name: "Instant Win ",
        element: <InstantWin />,
        exact: true,
      },
      {
        path: routeNames.SIGNUP,
        element: <ChooseVenue />,
      },
      {
        path: routeNames.SIGNUP_DETAILS,
        element: <SignupForm />,
      },
    ],
  },
  {
    path: routeNames.AGE_GATE,
    element: <AgeGate />,
    name: "Age Gate",
  },
  {
    path: routeNames.STARPUBS_TERMS,
    element: <StarPubsTerms />,
    name: "Star pubs Terms & Conditions",
  },
  {
    path: routeNames.STONEGATE_TERMS,
    element: <StoneGateTerms />,
    name: "Stonegate Terms & Conditions",
  },
  {
    path: routeNames.GREENE_KING_TERMS,
    element: <GreeneKingTerms />,
    name: "Greene King Terms & Conditions",
  },
  {
    path: routeNames.COOKIES,
    element: <CookiePolicy />,
    name: "Cookie Policy",
  },
  {
    path: "*",
    loader: () => {
      return redirect("/");
    },
  },
]);
