const routeNames = {
  LANDING: "/",
  SIGNUP: "/signup",
  SIGNUP_DETAILS: "/signup/details",
  SUCCESS: "/success",
  INSTANT_WIN_SCAN: "/instant-win/:id",
  SCAN: "/scan/:id",
  NOT_WINNER: "/not-winner",
  ALREADY_ENTERED: "/already-entered",
  OUTHOURS: "/out-of-hours",
  PREENTRY: "/pre-entry",
  CLOSED: "/closed",
  GENERIC_TERMS: "/terms",
  STARPUBS_TERMS: "/starpubs/terms",
  GREENE_KING_TERMS: "/terms",
  STONEGATE_TERMS: "/stonegate/terms",
  COOKIES: "/cookie-policy",
  PRIVACY: "/privacy-policy",
  AGE_GATE: "/age-gate",
  ALREADY_CLAIMED: "/already-claimed",
  LINK_EXPIRED: "/link-expired",
  CLAIMED: "/claimed",
  CLAIM_PRIZE: "/claim-prize/:id",
};

export default routeNames;
