const ErrorTypes = {
  ALREADY_ENTERED: 101,
  ENTRY_NOT_EXISTS: 102,
  RECOGNITION_FAILED: 103,
  NOT_WINNER: 104,
  ALREADY_CLAIMED: 105,
  LINK_EXPIRED: 106,

  CAMPAIGN_PRE_ENTRY: 1001,
  CAMPAIGN_OUT_HOURS: 1002,
  CAMPAIGN_CLOSED: 1003,
};

export default ErrorTypes;
