import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routeNames from "../../constants/routeNames";
import { LoadingContext } from "../../context/LoadingContext";

const Closed = () => {
  const [data, setData] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (!location?.state?.redirected) {
      navigate(routeNames.LANDING);
    }
    setData(location?.state?.data);
    setLoading(false);
  }, []);

  return (
    <div>
      <h1 className="page-title">{data?.title}</h1>
      <p className="description mb-6">{data?.description}</p>
      <p className="description">{data?.message}</p>
    </div>
  );
};

export default Closed;
