import starPubs from "../assets/images/retailer/star.svg";
import greeneKing from "../assets/images/retailer/greeneking.svg";
import stoneGate from "../assets/images/retailer/stonegate.svg";
export const Retailers = {
  GREENE_KING: {
    title: "Greene King",
    icon: greeneKing,
    text: "You will also be entered into an additional prize draw for your chance to win Investec Champions Cup Final 2024 tickets and a Legend Meet & Greet.",
    claimText:
      "You will also be entered into an additional prize draw for your chance to win Investec Champions Cup Final 2024 tickets and a Legend Meet & Greet. This will be drawn at the end of the campaign.",
    terms: "/terms",
  },
  GREENE_KING_IW: {
    title: "Greene King IW",
    icon: greeneKing,
    text: "You will also be entered into an additional prize draw for your chance to win Investec Champions Cup Final 2024 tickets and a Legend Meet & Greet.",
    claimText:
      "You will also be into an additional prize draw for your chance to win Investec Champions Cup Final 2024 tickets and a Legend Meet & Greet. This will be drawn at the end of the campaign.",
    terms: "/terms",
  },
  GREENE_KING_IFT: {
    title: "ATSC@LE - Greene King - IFT",
    icon: greeneKing,
    text: "You will also be entered into an additional prize draw for your chance to win Investec Champions Cup Final 2024 tickets and a Legend Meet & Greet.",
    claimText:
      "You will also be into an additional prize draw for your chance to win Investec Champions Cup Final 2024 tickets and a Legend Meet & Greet. This will be drawn at the end of the campaign.",
    terms: "/terms",
  },
  GREENE_KING_MANAGED: {
    title: "ATSC@LE - Greene King - Managed",
    icon: greeneKing,
    text: "You will also be entered into an additional prize draw for your chance to win Investec Champions Cup Final 2024 tickets and a Legend Meet & Greet.",
    claimText:
      "You will also be entered into an additional prize draw for your chance to win Investec Champions Cup Final 2024 tickets and a Legend Meet & Greet. This will be drawn at the end of the campaign.",
    terms: "/terms",
  },
  STAR_PUBS_AND_BARS: {
    title: "ATSC@LE - Star Pubs & Bars",
    icon: starPubs,
    text: "You will also been entered into an additional prize draw for your chance to win sporting event tickets, gift cards and Heineken merchandise. This will be drawn at the end of the campaign.",
    claimText:
      "You have also been entered into an additional prize draw for your chance to win sporting event tickets, gift cards and Heineken merchandise. This will be drawn at the end of the campaign.",
    terms: "/starpubs/terms",
  },
  STAR_PUBS_AND_BARS_IW: {
    title: "Star Pubs & Bars IW",
    icon: starPubs,
    text: "You will also been entered into an additional prize draw for your chance to win sporting event tickets, gift cards and Heineken merchandise. This will be drawn at the end of the campaign.",
    claimText:
      "You have also been entered into an additional prize draw for your chance to win sporting event tickets, gift cards and Heineken merchandise. This will be drawn at the end of the campaign.",
    terms: "/starpubs/terms",
  },
  STAR_PUBS_JAT: {
    title: "Instant Win - Star JAT/Additionals",
    icon: starPubs,
    text: "You will also been entered into an additional prize draw for your chance to win sporting event tickets, gift cards and Heineken merchandise. This will be drawn at the end of the campaign.",
    claimText:
      "You have also been entered into an additional prize draw for your chance to win sporting event tickets, gift cards and Heineken merchandise. This will be drawn at the end of the campaign.",
    terms: "/starpubs/terms",
  },
  STONEGATE: {
    title: "Stonegate",
    icon: stoneGate,
    text: "You will also been entered into an additional prize draw for your chance to win sporting event tickets. This will be drawn at the end of the campaign.",
    claimText:
      "You have also been entered into an additional prize draw for your chance to win sporting event tickets. This will be drawn at the end of the campaign.",
    terms: "/stonegate/terms",
  },
  STONEGATE_IW: {
    title: "Stonegate IW",
    icon: stoneGate,
    text: "You will also been entered into an additional prize draw for your chance to win sporting event tickets. This will be drawn at the end of the campaign.",
    claimText:
      "You have also been entered into an additional prize draw for your chance to win sporting event tickets. This will be drawn at the end of the campaign.",
    terms: "/stonegate/terms",
  },
  STONEGATE_MANAGED: {
    title: "Instant Win - Stonegate Managed",
    icon: stoneGate,
    text: "You will also been entered into an additional prize draw for your chance to win sporting event tickets. This will be drawn at the end of the campaign.",
    claimText:
      "You have also been entered into an additional prize draw for your chance to win sporting event tickets. This will be drawn at the end of the campaign.",
    terms: "/stonegate/terms",
  },
  STONEGATE_LT: {
    title: "ATSC@LE - Stonegate L&T",
    icon: stoneGate,
    text: "You will also been entered into an additional prize draw for your chance to win sporting event tickets. This will be drawn at the end of the campaign.",
    claimText:
      "You have also been entered into an additional prize draw for your chance to win sporting event tickets. This will be drawn at the end of the campaign.",
    terms: "/stonegate/terms",
  },
  OTHER: {
    title: "Other",
    icon: "",
    text: "",
    claimText: "",
    terms: "/terms",
  },
  OTHER_IW: {
    title: "Other IW",
    icon: "",
    text: "",
    claimText: "",
    terms: "/terms",
  },
  GENERIC: {
    title: "",
    icon: "",
    text: "",
    claimText: "",
    terms: "/terms",
  },
};
