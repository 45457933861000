import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routeNames from "../../constants/routeNames";
import { LoadingContext } from "../../context/LoadingContext";

const SignupSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (!location?.state?.redirected) {
      navigate(routeNames.LANDING);
    }
    setLoading(false);
  }, []);

  return (
    <div>
      <h1 className="page-title">Thank you</h1>
      <p className="description mb-6">Thank you for entering your details.</p>
      <p className="!font-normal description mb-6">
        {"We'll"} send an email to your inbox shortly. Make sure you follow the
        link to activate your entry.
      </p>
      <p className="description !font-normal mb-6">
        If {"it's"} not come through you may need to check your junk folder.
      </p>
    </div>
  );
};

export default SignupSuccess;
