import { useContext, useEffect } from "react";
import beers from "../../assets/images/beers.png";
import { LoadingContext } from "../../context/LoadingContext";
const Claimed = () => {
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div>
      <h1 className="page-title">Thanks</h1>
      <p className="description mb-6">
        Your prize will be processed and delivered to you.
      </p>
      <p className="description !font-normal mb-6">
        We hope you enjoy your prize and celebrating 150 Years of Heineken.
      </p>
      <p className="description mb-6 !font-normal">Heineken®</p>
      <img src={beers} className="mx-auto" />
    </div>
  );
};

export default Claimed;
