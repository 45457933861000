import { useNavigate } from "react-router-dom";
import banner from "../../assets/images/banner.png";
import ApiService from "../../services/ApiService";
import { errorHandler } from "../../helpers/errorHelper";
import { LoadingContext } from "../../context/LoadingContext";
import { useContext, useEffect } from "react";
import routeNames from "../../constants/routeNames";
const Landing = () => {
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(false);
  }, []);

  const checkCampaign = () => {
    ApiService.checkCampaign().then((res) => {
      if (res?.result && res?.data) {
        navigate(routeNames.SIGNUP, {
          state: {
            redirected: true,
          },
        });
      } else {
        errorHandler(res, navigate);
      }
    });
  };

  return (
    <div className="text-center">
      <h1 className="page-title !mb-6">
        <p className="text-4xl">WIN</p>Investec Champions Cup Final Tickets 2024
        and a Legend Meet & Greet
      </h1>

      <img src={banner} className="mx-auto max-w-[350px] my-2 w-full" />
      <button className="heineken-btn mt-6" onClick={checkCampaign}>
        Enter now
      </button>
    </div>
  );
};

export default Landing;
