import { useContext, useEffect } from "react";
import { LoadingContext } from "../../context/LoadingContext";

const NotWinner = () => {
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <div>
      <h1 className="page-title">Sorry</h1>
      <p className="description mb-6">
        {"You're"} not a winner this time, but there are still more chances to
        win.
      </p>
      <p className="description !font-normal">
        Prizes are available every week so try again soon.
      </p>
    </div>
  );
};

export default NotWinner;
