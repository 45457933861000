import React, { useState, useEffect, useContext } from "react";
import { LoadingContext } from "../../context/LoadingContext";

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(false);
    return () => {
      // Cleanup any resources or subscriptions here
    };
  });

  const handleOnError = (error, errorInfo) => {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
    setHasError(true);
  };

  if (hasError) {
    // Render your custom error message or fallback UI
    return <div>Something went wrong.</div>;
  }

  return React.Children.map(children, (child) =>
    React.cloneElement(child, {
      onError: handleOnError,
    })
  );
}

export default ErrorBoundary;
