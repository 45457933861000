import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routeNames from "../../constants/routeNames";
import moment from "moment";
import { LoadingContext } from "../../context/LoadingContext";

const AlreadyEntered = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (!location?.state?.redirected || !location?.state?.data) {
      navigate(routeNames.LANDING);
    }
    setLoading(false);
  }, []);

  return (
    <div>
      <h1 className="page-title">Sorry</h1>
      <p className="description mb-6">
        You can only enter this promotion once per week.
      </p>
      <p className="description">
        You can enter again on{" "}
        {moment.utc(location?.state?.data?.canSignOn).format("DD/MM/YYYY")}.
      </p>
    </div>
  );
};

export default AlreadyEntered;
