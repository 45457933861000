import { useContext, useEffect } from "react";
import { LoadingContext } from "../../../context/LoadingContext";

const GreeneKingTerms = () => {
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <>
      <h1 className="page-title"> Terms & Conditions</h1>
      <p className="description !text-left !font-normal mb-3">
        These Terms and Conditions prevail in the event of any conflict or
        inconsistency with any other communications, including advertising or
        promotional materials. Entry instructions are deemed to form part of the
        terms and conditions and by entering this promotion all participants
        will be deemed to have accepted and be bound by the terms and
        conditions. Please retain a copy for your information.
      </p>
      <p className="description !text-left !font-normal mb-2">
        The personal data provided by entrants will only be used by the Promoter
        for the purpose of conducting the promotion and at all times in
        accordance with the Data Protection Act 2018 and the {"Promoter’s"}{" "}
        Privacy Policy (available at{" "}
        <a
          href="https://www.heineken.co.uk/legal-hub/heineken-brands-privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          www.heineken.co.uk/legal-hub/heineken-brands-privacy-policy
        </a>{" "}
        ).&nbsp; From time to time, the Promoter may share entrants’ personal
        data with their agents/representatives to assist with administering the
        promotion and contacting entrants (as necessary) and/or fulfilling the
        prize(s).
      </p>
      <p className="description !text-left !font-normal mb-2">
        <ol className="list-decimal pl-8" start="1">
          <li>
            Only open to residents in the UK aged 18 or over, excluding
            employees of any company in the Heineken group and any person whom,
            in the Promoter&rsquo;s reasonable opinion, should be excluded due
            to their involvement or connection with this promotion.
          </li>
          <li>
            <p className="mb-3">How to take part:</p>
            <ol className="pl-8 list-[lower-alpha]">
              <li>
                Purchase any Heineken or Heineken 0.0 product via the 10% off
                drinks code in the Greene King sports app to be directed to{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.150.heineken.com"
                >
                  www.150.heineken.com
                </a>{" "}
                or go direct to entry page at{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.150.heineken.com"
                >
                  www.150.heineken.com
                </a>
                .
              </li>
              <li>Select the venue from which you made your purchase.</li>
              <li>
                Confirm that you are over the age of 18 and eligible to take
                part in this promotion.
              </li>
              <li>Enter your first name, last name and email address.</li>
              <li>
                Take a photograph of your purchase and upload it onto the
                website.
              </li>
              <li>NO PURCHASE NECESSARY IN SCOTLAND.</li>
              <li>
                No purchase necessary for entrants who have participated in the
                free pint offer in England. No photograph upload required.
              </li>
              <li>
                Confirm your acceptance of the promotion terms and conditions.
              </li>
              <li>All valid entries will be entered into the prize draw.</li>
            </ol>
          </li>
          <li>
            Standard network rates apply. Entry is limited to one per person per
            week
          </li>
          <li>
            Entries must be made personally. Entries made through agents/third
            parties, bulk entries or automated entries are invalid. No multiple
            entries from a single IP address in excess of the restriction in
            Term 3 will be valid. The Proof of sending is not proof of receipt
            and promoter does not accept any responsibility for the non receipt
            or the late receipt of entry due to network failure or for any
            associated costs to entrants. No entries submitted via any other
            means will be accepted.
            <br /> <br /> The Promoter does not guarantee uninterrupted or
            secure access to the web entry system. Any mechanical reproduction
            or automated entries is prohibited, and any use of such automated
            devices will cause disqualification
          </li>
          <li>
            This promotion begins at 00.01 on 8<sup>th</sup> September 2023. The
            promotion will come to an end at midnight on 28<sup>th</sup> October
            2023.
          </li>
          <li>
            <span>PRIZE DETAILS</span>
            <p className="mt-3 mb-3">
              For all entrants there is a chance to win the top tier prize of 1
              x pair of rugby Champions Cup Final 2024 Tickets including a
              minimum 4* hotel accommodation on Friday 24th May 2024 and
              Saturday 25th May 2024 (1 room per pair of tickets based on 2
              adults aged 18+ sharing a twin/double room) and £150 travel
              allowance per winning pair (no additional travel, subsistence,
              gratuities, costs of a personal nature or accommodation are
              included. These must be borne by the winner and their guest).
            </p>
            <p className="description !text-left !font-normal mb-3">
              The top tier prize also includes the chance to win a meet and
              greet with a retired international rugby sporting legend at a
              Greene King venue in a central London location. This will take
              place on Saturday 25th May 2024 before heading to the final of the
              rugby Champions Cup Final 2024 at Tottenham Hotspur Stadium which
              they need to arrange their own travel too. This includes 1 winner
              + 1 guest going into the venue and have a VIP style table in a
              Heineken branded area. Light refreshments and 2 free drinks also
              provided on the day. During the experience, the winner and guests
              will have a photo opportunity and Q&A session with the legend
              before heading to the final of the Champions Cup 2024. Winner and
              guest must arrange travel to Tottenham Hotspur Stadium themselves.
              Duration of experience with rugby legend: x1.5 hours (not
              including travel time).
            </p>

            <p className="description !text-left !font-normal mb-3">
              There are also 150 x branded rugby balls to be given away as
              runner-up prizes and 32 x pairs of standard admission tickets to
              the Men's European Professional Club Rugby 2024 UK based game.
            </p>
          </li>

          <li>
            All prize winners will be entered into a prize draw on 30.10.2023.
            The winner will be notified instantly if they have won the rugby
            balls and the 32 x pairs of Men’s Champion Cup group stage tickets.
            The winner will be notified immediately that someone will be in
            touch if they are a lucky winner for the main prize draw and will be
            contacted 14 days after the promotion ends if they have won. The
            winner will receive a follow up email to confirm prize win and
            arrange for prize fulfilment. If the winner cannot be contacted
            within 7 working days, the Promoter reserves the right to add the
            prize back into the prize pot and will include the prize in a mop up
            draw on 17/11/2023 where winners will be selected at random from all
            valid entrants who have not already won a prize. Mop up prize draw
            winners will be informed by email by 30.10.2023. If the mop up prize
            draw winner cannot be contacted within 7 working days’ the Promoter
            reserves the right to select a new winner (and so on until a winner
            can be contacted who can accept the prize). No entrant may win more
            than one prize. Lower tier prizes will be sent within 8 weeks after
            the promotional period ends.
          </li>
          <li>
            The Promoter reserves the right to verify all entries and to refuse
            to honour a prize and/or refuse further participation and disqualify
            the participant where there are reasonable grounds to believe there
            has been a breach of these terms and conditions or any instructions
            forming part of entry. The Promoter will be the final arbiter in any
            decisions and these will be binding and no correspondence will be
            entered into.
          </li>
          <li>
            Winners’ details will be available from 28/10/2023 – 31/12/2023. By
            entering, the winner consents to such information being disclosed
            upon request.
          </li>
          <li>
            Ticket terms and conditions apply – see event operators website for
            details. Tickets are not for resale and the event organiser reserves
            the right to invalidate any tickets sold contrary to the ticket
            terms.
            <ul className="pl-8">
              <li>
                <a
                  href="https://www.epcrugby.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  https://www.epcrugby.com/
                </a>
              </li>
            </ul>
          </li>
          <li>
            There is no cash or other alternative to the prizes in whole or in
            part. Prizes are not transferrable. The Promoter reserves the right
            to reclaim the prize if it is transferred.
          </li>
          <li>
            The Promoter is not responsible for any third-party acts or
            omissions.
          </li>
          <li>
            The Promoter reserves the right to cancel or amend this promotion
            due to events or circumstances arising beyond its control.
          </li>
          <li>
            The decision of the promoter in all matters is final and binding and
            no correspondence will be entered into.
          </li>
          <li>
            The promoter cannot guarantee that any event will be free from
            disruptions, failings or cancellations. The Promoter is not liable
            for such disruptions, failings or cancellations unless they are
            caused by our negligence. Any requests for refunds or compensation
            arising from them should be sent to the operator of the event. The
            Promoter can provide you with their details on request.
          </li>
          <li>
            All taxes (including without limitation national & local taxes) in
            connection with any prize & the reporting consequence thereof, are
            the sole responsibility of the prize winner.
          </li>
          <li>
            These rules and any dispute or difference arising out of or in
            connection with them shall be governed by and construed in
            accordance with English law. The Promoter and each entrant
            irrevocably agrees to submit to the exclusive jurisdiction of the
            Courts of England over any claim or matter arising under or in
            connection with these rules or the legal relationships established
            by this agreement.
          </li>
        </ol>
        <span className="font-bold">
          Promoter: Heineken UK Limited, 3-4 Broadway Park, South Gyle Broadway,
          EDINBURGH, EH12 9JZ
        </span>
      </p>
    </>
  );
};

export default GreeneKingTerms;
