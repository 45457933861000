import { useContext, useEffect } from "react";
import { LoadingContext } from "../../../context/LoadingContext";

const StoneGateTerms = () => {
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <>
      <h1 className="page-title">Stonegate Terms & Conditions</h1>
      <p className="description !text-left !font-normal mb-3">
        These Terms and Conditions prevail in the event of any conflict or
        inconsistency with any other communications, including advertising or
        promotional materials. Entry instructions are deemed to form part of the
        terms and conditions and by entering this promotion all participants
        will be deemed to have accepted and be bound by the terms and
        conditions. Please retain a copy for your information.
      </p>
      <p className="description !text-left !font-normal mb-2">
        The personal data provided by entrants will only be used by the Promoter
        for the purpose of conducting the promotion and at all times in
        accordance with the Data Protection Act 2018 and the Promoter’s Privacy
        Policy (available at{" "}
        <a
          href="https://www.heineken.co.uk/legal-hub/heineken-brands-privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          www.heineken.co.uk/legal-hub/heineken-brands-privacy-policy
        </a>
        ).&nbsp; From time to time, the Promoter may share entrants’ personal
        data with their agents/representatives to assist with administering the
        promotion and contacting entrants (as necessary) and/or fulfilling the
        prize(s).
      </p>
      <p className="description !text-left !font-normal mb-2">
        <ol className="list-decimal pl-8" start="1">
          <li>
            Only open to residents in the UK aged 18 or over, excluding
            employees of any company in the Heineken group and any person whom,
            in the Promoter’s reasonable opinion, should be excluded due to
            their involvement or connection with this promotion.
          </li>
          <li>
            <p className="mb-3">How to take part:</p>
            <ol className="pl-8 list-[lower-alpha]">
              <li>
                Purchase any Heineken, Heineken Silver or Heineken 0.0 product.
                Scan the QR code on point of sale items in-venue to be directed
                to{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.150.heineken.com"
                >
                  www.150.heineken.com
                </a>{" "}
                or go direct to entry page at{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.150.heineken.com"
                >
                  www.150.heineken.com
                </a>
                .
              </li>
              <li>Select the venue from which you made your purchase.</li>
              <li>
                Confirm that you are over the age of 18 and eligible to take
                part in this promotion.
              </li>
              <li>Enter your first name, last name and email address.</li>
              <li>
                Take a photograph of your purchase and upload it onto the
                website.
              </li>
              <li>
                NO PURCHASE NECESSARY IN SCOTLAND, no photograph required. Scan
                QR code on point of sale in venue to be directed to{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.150.heineken.com"
                >
                  www.150.heineken.com
                </a>{" "}
                or go direct to entry page at to{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.150.heineken.com"
                >
                  www.150.heineken.com
                </a>
                .
              </li>
              <li>
                No purchase necessary for entrants who have participated in the
                free pint offer in England. No photograph upload required.
              </li>
              <li>
                Confirm your acceptance of the promotion terms and conditions.
              </li>
              <li>
                All valid entries will be entered into the winning moments prize
                draws.
              </li>
            </ol>
          </li>
          <li>
            Standard network rates apply. Entry is limited to one per person per
            week
          </li>
          <li>
            Entries must be made personally. Entries made through agents/third
            parties, bulk entries or automated entries are invalid. No multiple
            entries from a single IP address in excess of the restriction in
            Term 3 will be valid. The Proof of sending is not proof of receipt
            and promoter does not accept any responsibility for the non receipt
            or the late receipt of entry due to network failure or for any
            associated costs to entrants. No entries submitted via any other
            means will be accepted.
            <br /> <br /> The Promoter does not guarantee uninterrupted or
            secure access to the web entry system. Any mechanical reproduction
            or automated entries is prohibited, and any use of such automated
            devices will cause disqualification
          </li>
          <li>
            This promotion begins at 00.01 on 1<sup>st</sup> August 2023. The
            promotion will come to an end at midnight on 31<sup>st</sup> August
            2023.
          </li>
          <li>
            <span>CORE CAMPAIGN</span>
            <p className="mt-3">
              For all entrants there is a chance of 350 prizes to be won in the
              core campaign. The prizes are:
            </p>
            <p className="mb-1 mt-3">Top tier:</p>

            <ul className="list-disc pl-8 mb-3">
              <li>1x pair of VIP tickets to the Dutch GP 2024</li>
              <li>1x pair of General Admission tickets to the Dutch GP 2024</li>
              <li>1x pair of VIP tickets to the GB GP 2024</li>
              <li>1x pair of General Admission tickets to the GB GP 2024</li>
              <li>
                1x pair of VIP tickets to the UEFA Champions League Final 2024
              </li>
              <li>
                5x pairs of standard admission tickets to the UEFA Champions
                League Final 2024
              </li>
            </ul>
            <p className="description !text-left !font-normal mb-3">
              Each prize will also include a minimum 4* hotel accommodation only
              (1 room per pair of tickets based on 2 adults aged 18+ sharing),
              economy return flights from London, Edinburgh or Manchester and
              £150 additional travel allowance per winner/pair (no additional
              travel, subsistence, gratuities, costs of a personal nature or
              accommodation are included. These must be borne by the winner and
              their guest) and a Heineken branded welcome pack including
              Heineken branded pint glass, branded pen, branded notebook,
              branded black roll-top bag and Heineken branded ice bucket stocked
              with Heineken 6x330ml. Winner and guests are responsible for
              ensuring they have appropriate visas and passports for travel.
            </p>
            <p className="mb-1 mt-3">Middle tier:</p>

            <ul className="list-disc pl-8 mb-3">
              <li>
                10x pairs of standard admission tickets to a UEFA Champions
                League 2024 UK based game.
              </li>
              <li>
                20x pairs of standard admission tickets to a Heineken Cup Rugby
                2024 UK based game.
              </li>
              <li>
                10x pairs of standard tickets to an T20 series cricket match at
                Emirates Old Trafford Cricket Stadium.
              </li>
            </ul>
            <p className="description !text-left !font-normal mb-3">
              Each middle tier prize will also include a £150 additional travel
              allowance per winner/pair (no additional travel, subsistence,
              gratuities, costs of a personal nature or expenses to be covered)
              and a Heineken branded welcome pack including Heineken branded
              pint glass, branded pen, branded notebook, branded black roll-top
              bag and Heineken branded ice bucket stocked with Heineken 6x330ml.
            </p>
            <p className="mb-1 mt-3">Lower tier:</p>

            <ul className="list-disc pl-8 mb-3">
              <li>100x UEFA online Gift Store Vouchers worth £100 each.</li>
              <li>
                150x Heineken branded 150 Year Anniversary Caps (£5.46 each)
              </li>
              <li>
                200x Heineken branded 150 Year Anniversary Footballs (£17.97
                each)
              </li>
            </ul>
          </li>

          <li>
            <p>STONEGATE CAMPAIGN</p>
            <p>
              For those consumers entering from a selected Stonegate venue there
              also 3 additional prizes to be won in the bespoke prize draw in
              Stonegate outlets. The prizes are:
            </p>
            <p className="mb-1 ">Top tier:</p>
            <ul className="list-disc pl-8 mb-3">
              <li>
                1 x pair of UCL Final 2024 tickets (standard, not hospitality)
                (2 tickets)
              </li>
              <li>
                1 x pair of Champions Cup Final 2024 Tickets (non-hospitality)
                (2 tickets)
              </li>
              <li>
                1 x pair of Silverstone F1 Grandstand tickets with Camping 2024
                (Friday, Saturday and Sunday) (2 tickets)
              </li>
            </ul>
            <p>
              Each prize will also include £150 additional travel allowance per
              winner/pair (no additional travel, subsistence, gratuities, costs
              of a personal nature or accommodation are included. These must be
              borne by the winner and their guest)
            </p>
          </li>
          <li>
            Prize winners for the core campaign will be determined by the
            proximity in time of receipt of their online entry to predetermined
            dates and times. These ‘winning moments’ have been allocated at
            random throughout the promotion period using a secure, independently
            verified computer-based programme prior to the start of the
            Promotion. The winners will be the entrants whose entry is received
            closest to the predetermined winning moment. Prizes have been
            allocated at random and are linked to a random winning moment.
          </li>
          <li>
            All prize winners in the core campaign will be informed immediately
            if they have won a prize. All prize winners in the bespoke campaign
            for the Stonegate will be entered into a prize draw on 15/09/2023.
            The winner will be notified that someone will be in touch if they
            are a lucky winner and will be contacted 14 days after the promotion
            ends if they have won. The winner will receive a follow up email to
            confirm prize win and arrange for prize fulfilment. If the winner
            cannot be contacted within 7 working days, the Promoter reserves the
            right to add the prize back into the prize pot and will include the
            prize in a mop up draw on 15/09/2023 where winners will be selected
            at random from all valid entrants who have not already won a prize.
            Mop up prize draw winners will be informed by email by 22.09.2023.
            If the mop up prize draw winner cannot be contacted within 7 working
            days’ the Promoter reserves the right to select a new winner (and so
            on until a winner can be contacted who can accept the prize). No
            entrant may win more than one prize. Lower tier prizes will be sent
            within 8 weeks after the promotional period ends.
          </li>
          <li>
            The Promoter reserves the right to verify all entries and to refuse
            to honour a prize and/or refuse further participation and disqualify
            the participant where there are reasonable grounds to believe there
            has been a breach of these terms and conditions or any instructions
            forming part of entry. The Promoter will be the final arbiter in any
            decisions and these will be binding and no correspondence will be
            entered into.
          </li>
          <li>
            Winners’ details will be available from 25/09/2023 – 30/11/2023. By
            entering, the winner consents to such information being disclosed
            upon request.
          </li>
          <li>
            Ticket terms and conditions apply – see event operators website for
            details. Tickets are not for resale and the event organiser reserves
            the right to invalidate any tickets sold contrary to the ticket
            terms.
            <ul className="pl-8">
              <li>
                <a
                  href="https://f1experiences.com/2024-dutch-grand-prix"
                  rel="noreferrer"
                  target="_blank"
                >
                  https://f1experiences.com/2024-dutch-grand-prix
                </a>
              </li>
              <li>
                <a
                  href="https://f1experiences.com/2024-british-grand-prix"
                  rel="noreferrer"
                  target="_blank"
                >
                  https://f1experiences.com/2024-british-grand-prix
                </a>
              </li>
              <li>
                <a
                  href="https://www.uefa.com/tickets/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.uefa.com/tickets/
                </a>
              </li>
              <li>
                <a
                  href="https://www.epcrugby.com/tottenham-2024-finals/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.epcrugby.com/tottenham-2024-finals/
                </a>
              </li>
              <li>
                <a
                  href="https://tickets.lancashirecricket.co.uk/list/events?lang=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://tickets.lancashirecricket.co.uk/list/events?lang=en
                </a>
              </li>
              <li>
                <a
                  href="https://store.uefa.com/collections/uefa-store-e-gift-card-champions-league"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://store.uefa.com/collections/uefa-store-e-gift-card-champions-league
                </a>
              </li>
            </ul>
          </li>
          <li>
            There is no cash or other alternative to the prizes in whole or in
            part. Prizes are not transferrable. The Promoter reserves the right
            to reclaim the prize if it is transferred.
          </li>
          <li>
            The Promoter is not responsible for any third-party acts or
            omissions.
          </li>
          <li>
            The Promoter reserves the right to cancel or amend this promotion
            due to events or circumstances arising beyond its control.
          </li>
          <li>
            The decision of the promoter in all matters is final and binding and
            no correspondence will be entered into.
          </li>
          <li>
            The promoter cannot guarantee that any event will be free from
            disruptions, failings or cancellations. The Promoter is not liable
            for such disruptions, failings or cancellations unless they are
            caused by our negligence. Any requests for refunds or compensation
            arising from them should be sent to the operator of the event. The
            Promoter can provide you with their details on request.
          </li>
          <li>
            All taxes (including without limitation national & local taxes) in
            connection with any prize & the reporting consequence thereof, are
            the sole responsibility of the prize winner.
          </li>
          <li>
            These rules and any dispute or difference arising out of or in
            connection with them shall be governed by and construed in
            accordance with English law. The Promoter and each entrant
            irrevocably agrees to submit to the exclusive jurisdiction of the
            Courts of England over any claim or matter arising under or in
            connection with these rules or the legal relationships established
            by this agreement.
          </li>
        </ol>
        <span className="font-bold">
          Promoter: Heineken UK Limited, 3-4 Broadway Park, South Gyle Broadway,
          EDINBURGH, EH12 9JZ
        </span>
      </p>
    </>
  );
};

export default StoneGateTerms;
