import "./VenueDropdown.css";
import { useEffect, useRef, useState } from "react";
import ReactVisibilitySensor from "react-visibility-sensor";

const Caret = ({ isOpen }) => {
  return (
    <svg
      width="16"
      className={`${!isOpen ? "rotate-180" : ""}`}
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.83008 1.32446L15.4707 7.96509C15.9297 8.42407 15.9297 9.16626 15.4707 9.62036L14.3672 10.7239C13.9082 11.1829 13.166 11.1829 12.7119 10.7239L8.00488 6.01684L3.29785 10.7239C2.83887 11.1829 2.09668 11.1829 1.64258 10.7239L0.539064 9.62036C0.0800792 9.16138 0.0800793 8.41919 0.539064 7.96509L7.17969 1.32446C7.62891 0.865478 8.37109 0.865478 8.83008 1.32446Z"
        fill="black"
      />
    </svg>
  );
};

const DropdownItem = ({ venue, onClick }) => {
  return (
    <div
      className="hover:bg-heinekenGreen mt-2 p-2 hover:rounded text-lightGray hover:text-white"
      onClick={() => {
        onClick(venue);
      }}
    >
      <label className=" cursor-pointer font-bold text-base leading-6 ">
        {venue?.name}
      </label>
      <p className="text-sm leading-6 ">{venue?.formattedAddress}</p>
    </div>
  );
};

export const VenueDropdown = ({
  list,
  selectVenue,
  hasError,
  options,
  loadMore,
  onQueryChange,
  userSearching,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const myRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const onSelect = (venue) => {
    selectVenue(venue);
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (isVisible && options.hasMore) {
      const params = { ...options, page: options.page + 1 };
      loadMore(params);
    }
  }, [isVisible]);

  return (
    <>
      <div className={`venue-dropdown ${hasError ? "!mb-0" : ""}`}>
        <div
          className="flex flex-row"
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}
        >
          <div className="dropdown-container">
            <label className="cursor-pointer">Select your venue</label>
          </div>
          <div className="dropdown-caret">
            <Caret isOpen={dropdownOpen} />
          </div>
        </div>
        {dropdownOpen && (
          <div className="dropdown-list" id="dropdown-list">
            <input
              type="text"
              placeholder="Search"
              onChange={onQueryChange}
              className="venue-search"
            />

            {list &&
              list?.map((venue, i) => {
                return (
                  <DropdownItem key={i} venue={venue} onClick={onSelect} />
                );
              })}
            <ReactVisibilitySensor
              onChange={(isVisible) => {
                setIsVisible(isVisible);
              }}
            >
              <div
                className={`py-2 ${userSearching ? "mt-2" : ""}`}
                hidden={!options.hasMore && !userSearching}
              >
                <div className="loading-spinner !w-5 !h-5  !border-r-darkGreen !border-t-darkGreen !border-b-darkGreen !border-l-lightGreen mx-auto" />
              </div>
            </ReactVisibilitySensor>
          </div>
        )}
      </div>
      {hasError && (
        <p className="mt-2 mb-8 text-white text-sm">Please choose your venue</p>
      )}
    </>
  );
};
