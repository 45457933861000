const helper = {
  dayOptions: () => {
    const days = [];
    for (let i = 1; i <= 31; i++) {
      let value = i < 10 ? "0" + i : i.toString();
      days.push(
        <option key={value} value={value}>
          {value}
        </option>
        // {
        // 	value, label: value
        // }
      );
    }
    return days;
  },
  monthOptions: () => {
    const months = [];
    for (let i = 1; i <= 12; i++) {
      let value = i < 10 ? "0" + i : i.toString();
      months.push(
        <option key={value} value={value}>
          {value}
        </option>
      );
    }
    return months;
  },
  yearOptions: () => {
    let currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 100; i--) {
      let value = i.toString();
      years.push(
        <option key={value} value={value}>
          {value}
        </option>
      );
    }
    return years;
  },
};

export default helper;
