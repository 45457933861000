import { useEffect, useState } from "react";
import { VenueDropdown } from "../../components/Signup/VenueDropdown/VenueDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import ApiService from "../../services/ApiService";

const RemoveIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 14.0266L8 8.02661M14 8.02661L8 14.0266"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11 21.0266C16.5228 21.0266 21 16.5495 21 11.0266C21 5.50376 16.5228 1.02661 11 1.02661C5.47715 1.02661 1 5.50376 1 11.0266C1 16.5495 5.47715 21.0266 11 21.0266Z"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
};

const ChooseVenue = () => {
  const [venueList, setVenueList] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [userSearching, setUserSearching] = useState(false);
  const [options, setOptions] = useState({
    page: 1,
    size: 10,
    query: "",
    hasMore: false,
  });

  const goToDetails = () => {
    if (selectedVenue) {
      navigate("/signup/details", {
        state: {
          venue: selectedVenue,
          redirected: true,
        },
      });
    } else {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (!location?.state?.redirected) {
      navigate("/");
    }
  }, []);

  const getVenues = (params, resetList) => {
    ApiService.getVenues(params.page, params.size, params.query).then((res) => {
      if (res && res?.result && res?.data) {
        if (resetList) {
          setVenueList(res?.data);
        } else {
          // eslint-disable-next-line no-unsafe-optional-chaining
          setVenueList([...venueList, ...res?.data]);
        }
        setOptions({ ...params, hasMore: res?.extra });
      }
    });
  };

  useEffect(() => {
    setUserSearching(true);
    setVenueList([]);
    let timer = setTimeout(() => {
      getVenues({ page: 1, size: options.size, query: options.query }, true);
      setUserSearching(false);
    }, 750);
    return () => clearTimeout(timer);
  }, [options.query]);

  const onVenueSelect = (venue) => {
    setSelectedVenue(venue);
    setHasError(false);
  };

  return (
    <div>
      <h1 className="page-title">Choose your venue</h1>
      <p className="font-bold text-base text-white leading-6 text-center mb-6">
        Choose your current venue from the list below.
      </p>
      <p className="text-base text-white leading-6 text-center mb-6">
        Search for your venue using its name, or the first half of its postcode.
      </p>
      {!selectedVenue && (
        <VenueDropdown
          list={venueList}
          selectVenue={onVenueSelect}
          hasError={hasError}
          options={options}
          userSearching={userSearching}
          loadMore={(props) => {
            getVenues(props);
          }}
          onQueryChange={(e) => {
            setOptions({ ...options, query: e.target.value });
          }}
        />
      )}
      {selectedVenue && (
        <div className="min-h-[56px] w-full flex flex-row bg-white rounded mb-8 px-4 py-2.5 ">
          <div className="w-5/6">
            <label className=" cursor-pointer font-bold text-base leading-6 ">
              {selectedVenue?.name}
            </label>
            <p className="text-sm leading-6 ">
              {selectedVenue?.formattedAddress}
            </p>
          </div>
          <div
            className="w-1/6  flex items-center justify-end cursor-pointer"
            onClick={() => {
              setSelectedVenue(null);
            }}
          >
            <RemoveIcon />
          </div>
        </div>
      )}
      <div className="text-center">
        <button className="heineken-btn" onClick={goToDetails}>
          Next
        </button>
      </div>
    </div>
  );
};

export default ChooseVenue;
