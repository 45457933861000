import { useEffect, useState } from "react";
import "./Signup.css";
import { useLocation, useNavigate } from "react-router-dom";
import ApiService from "../../services/ApiService";
import routeNames from "../../constants/routeNames";
import { errorHandler } from "../../helpers/errorHelper";
import { Retailers } from "../../constants/Retailers";

const SignupForm = () => {
  const location = useLocation();
  const [selectedRetailer, setSelectedRetailer] = useState(
    Retailers.GREENE_KING
  );
  const [hasError, setHasError] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    terms: false,
    offers: false,
    venue: null,
  });

  useEffect(() => {
    setUser({ ...user, venue: location?.state?.venue });
    // setRetailerDetails(location?.state?.venue?._retailer);
  }, [location, selectedRetailer]);

  const setRetailerDetails = (retailer) => {
    if (retailer) {
      const ret = retailer?.name;
      switch (ret) {
        case Retailers.GREENE_KING.title:
          setSelectedRetailer(Retailers.GREENE_KING);
          break;
        case Retailers.GREENE_KING_IW.title:
          setSelectedRetailer(Retailers.GREENE_KING);
          break;
        case Retailers.GREENE_KING_IFT.title:
          setSelectedRetailer(Retailers.GREENE_KING);
          break;
        case Retailers.GREENE_KING_MANAGED.title:
          setSelectedRetailer(Retailers.GREENE_KING);
          break;
        case Retailers.STONEGATE.title:
          setSelectedRetailer(Retailers.STONEGATE);
          break;
        case Retailers.STONEGATE_IW.title:
          setSelectedRetailer(Retailers.STONEGATE);
          break;
        case Retailers.STONEGATE_LT.title:
          setSelectedRetailer(Retailers.STONEGATE);
          break;
        case Retailers.STONEGATE_MANAGED.title:
          setSelectedRetailer(Retailers.STONEGATE);
          break;
        case Retailers.STAR_PUBS_AND_BARS.title:
          setSelectedRetailer(Retailers.STAR_PUBS_AND_BARS);
          break;
        case Retailers.STAR_PUBS_AND_BARS_IW.title:
          setSelectedRetailer(Retailers.STAR_PUBS_AND_BARS);
          break;
        case Retailers.STAR_PUBS_JAT.title:
          setSelectedRetailer(Retailers.STAR_PUBS_AND_BARS);
          break;
        case Retailers.OTHER.title:
          setSelectedRetailer(Retailers.GENERIC);
          break;
        case Retailers.OTHER_IW.title:
          setSelectedRetailer(Retailers.GENERIC);
          break;
        case Retailers.GENERIC.title:
          setSelectedRetailer(Retailers.GENERIC);
          break;
        default:
          setSelectedRetailer(Retailers.GENERIC);
          break;
      }
    }
  };

  useEffect(() => {
    if (!location?.state?.redirected) {
      navigate("/");
    }
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    const data = { ...user };
    data[name] = value;
    setUser(data);
  };

  const validateEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const validateForm = () => {
    if (
      !user?.email ||
      !user?.firstName ||
      !user?.lastName ||
      !user?.phoneNumber ||
      !user?.venue ||
      !user?.terms ||
      !emailValid
    ) {
      setHasError(true);
      return false;
    }
    return true;
  };

  const submit = () => {
    const validationSuccess = validateForm();
    if (validationSuccess) {
      setSubmitLoading(true);
      ApiService.register(user).then((res) => {
        if (res && res?.result && res?.data) {
          setSubmitLoading(false);
          navigate(routeNames.SUCCESS, {
            state: {
              redirected: true,
            },
          });
        } else {
          setSubmitLoading(false);
          errorHandler(res, navigate);
        }
      });
    } else {
      return;
    }
  };

  return (
    <div>
      {selectedRetailer?.icon && (
        <img
          src={selectedRetailer?.icon}
          className="mb-6 max-w-[200px] w-full mx-auto"
        />
      )}
      <h2 className="enter-details-title">Enter your details</h2>
      <p className="details-description mb-4">
        Enter your details below for a chance to win tickets to the Men's
        European Professional Club Rugby 2024 or a Branded Rugby Ball.
      </p>

      {selectedRetailer?.text && (
        <p className="details-description mb-4">{selectedRetailer.text}</p>
      )}

      <div className="details-form">
        <input
          type="text"
          onChange={onChange}
          placeholder="First Name"
          className="form-input"
          name="firstName"
        />
        {hasError && !user?.firstName && (
          <p className="input-error">Enter your first name</p>
        )}
        <input
          type="text"
          onChange={onChange}
          placeholder="Last Name"
          className="form-input"
          name="lastName"
        />
        {hasError && !user?.lastName && (
          <p className="input-error">Enter your last name</p>
        )}
        <input
          type="email"
          onChange={(event) => {
            onChange(event);
            validateEmail(event.target.value);
          }}
          placeholder="Email Address"
          className="form-input"
          name="email"
        />
        {hasError && !user?.email && (
          <p className="input-error">Enter your email</p>
        )}
        {hasError && user?.email && !emailValid && (
          <p className="input-error">Enter a valid email</p>
        )}
        <input
          type="number"
          onChange={onChange}
          placeholder="Mobile Number"
          className="form-input"
          name="phoneNumber"
        />
        {hasError && !user?.phoneNumber && (
          <p className="input-error">Enter your mobile number</p>
        )}
        <label className="flex items-start space-x-2 cursor-pointer mb-4">
          <div
            className={`w-5 h-5 border-[2px] border-white rounded ${
              user?.terms ? "bg-darkGreen" : ""
            }`}
            style={{ padding: user?.terms ? "2px" : "0" }}
          >
            {user?.terms && <div className="w-full h-full bg-white"></div>}
          </div>
          <p className="text-white w-[calc(100%-1.25rem)] text-base leading-6 ">
            I have read and agree to the{" "}
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href={selectedRetailer.terms}
            >
              Terms & Conditions
            </a>{" "}
            and{" "}
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href="https://www.heineken.co.uk/legal-hub/heineken-brands-privacy-policy"
            >
              Privacy Policy
            </a>
            .
          </p>
          <input
            type="checkbox"
            className="hidden"
            checked={user?.terms}
            onChange={(e) => {
              setUser({ ...user, terms: e.target.checked });
            }}
          />
        </label>
        {hasError && !user?.terms && (
          <p className="input-error">
            You must agree with our terms & conditions.
          </p>
        )}

        <label className="flex items-start space-x-2 cursor-pointer">
          <div
            className={`w-5 h-5 border-[2px] border-white rounded ${
              user?.offers ? "bg-darkGreen" : ""
            }`}
            style={{ padding: user?.offers ? "2px" : "0" }}
          >
            {user?.offers && <div className="w-full h-full bg-white"></div>}
          </div>
          <p className="text-white w-[calc(100%-1.25rem)] text-base leading-6 ">
            Tick to receive personalised offers, promotions and news about our
            brands, pubs and special events via email, and online targeted
            adverts. Heineken® UK Limited will process your data in accordance
            with our{" "}
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href="https://www.heineken.co.uk/legal-hub/heineken-brands-privacy-policy"
            >
              Privacy Policy
            </a>
            , which describes our profiling and audience matching/segment
            activities, and the technologies we use to monitor the effectiveness
            of our campaigns. You can withdraw consent by clicking unsubscribe
            in any email or emailing{" "}
            <a
              className="underline"
              href="mailto:MTK-unsubscribe@heineken.co.uk"
            >
              MTK-unsubscribe@heineken.co.uk
            </a>{" "}
          </p>
          <input
            type="checkbox"
            className="hidden"
            checked={user?.offers}
            onChange={(e) => {
              setUser({ ...user, offers: e.target.checked });
            }}
          />
        </label>
      </div>
      <div className="text-center mt-8">
        <button
          onClick={submit}
          disabled={submitLoading}
          className="heineken-btn "
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default SignupForm;
