import { useContext, useEffect } from "react";
import loadingIcon from "../../assets/images/loading.gif";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "../../services/ApiService";
import { errorHandler } from "../../helpers/errorHelper";
import "./InstantWin.css";
import { LoadingContext } from "../../context/LoadingContext";
const InstantWin = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(false);

    setTimeout(() => {
      ApiService.checkInstantUser(params.id).then((res) => {
        if (res && res?.result && res?.data) {
          if (res?.data?.user && res?.data?.user?.winner) {
            navigate(`/claim-prize/${params.id}`, {
              state: {
                user: res?.data?.user,
                redirected: true,
              },
            });
          }
        } else {
          errorHandler(res, navigate);
        }
      });
    }, 4000);
  }, []);

  return (
    <div>
      <div className="w-full">
        <img src={loadingIcon} alt="loading" className="loading-icon mx-auto" />
      </div>
    </div>
  );
};

export default InstantWin;
