import logo from "./assets/images/logo.svg";
import banner from "./assets/images/banner.png";
import Footer from "./components/Footer/Footer";
import neonMobile from "./assets/images/neonMobile.png";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { Suspense, useContext } from "react";
import { LoadingContext } from "./context/LoadingContext";
import ErrorBoundary from "./containers/Simple/ErrorBoundary";
import CookieBar from "./components/CookieBar/CookieBar";

function App() {
  const { loading } = useContext(LoadingContext);

  return (
    <div className="site-wrapper">
      <div className={`loading-wrapper ${!loading && "done"}`}>
        <div className="loading-spinner" />
      </div>
      <div className="layout">
        <div className="left">
          <div className="column banner">
            <a href="/">
              <img src={logo} className="w-[70%] lg:w-auto mx-auto max-w-[500px]" />
            </a>
            <img src={banner} className="w-auto mt-14 desktop-only max-w-[500px]" />
          </div>
          <div className="column wrapper">
            <div className="content">
              <Suspense fallback={<></>}>
                <ErrorBoundary>
                  <RouterProvider router={router}></RouterProvider>
                </ErrorBoundary>
              </Suspense>
            </div>
          </div>
        </div>
        <CookieBar />
        <Footer />
      </div>
      <div className="vertical">
        <img src={neonMobile} className="mobile-only " />
      </div>
    </div>
  );
}

export default App;
