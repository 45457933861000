import Endpoints from "../constants/Endpoints";

const reqHeaders = new Headers();
reqHeaders.append("Content-Type", "application/json");

class Api {
  async checkCampaign() {
    const requestOptions = {
      method: "GET",
      headers: reqHeaders,
    };
    try {
      const respJson = await fetch(Endpoints.checkCampaign, requestOptions);
      return await respJson.json();
    } catch (error) {
      return error.message;
    }
  }

  async register(data) {
    const requestOptions = {
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify(data),
    };
    try {
      const respJson = await fetch(Endpoints.register, requestOptions);
      return await respJson.json();
    } catch (error) {
      return error.message;
    }
  }

  async claim(data) {
    const requestOptions = {
      method: "PUT",
      headers: reqHeaders,
      body: JSON.stringify(data),
    };
    try {
      const respJson = await fetch(Endpoints.claim, requestOptions);
      return await respJson.json();
    } catch (error) {
      return error.message;
    }
  }

  async createVenue(data) {
    const requestOptions = {
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify(data),
    };
    try {
      const respJson = await fetch(Endpoints.createVenue, requestOptions);
      return await respJson.json();
    } catch (error) {
      return error.message;
    }
  }

  async getNearVenues(data) {
    const requestOptions = {
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify(data),
    };
    try {
      const respJson = await fetch(Endpoints.getNearVenues, requestOptions);
      return await respJson.json();
    } catch (error) {
      return error.message;
    }
  }
  
  async getVenues(page, size, query) {
    const requestOptions = {
      method: "GET",
      headers: reqHeaders,
    };
    try {
      const respJson = await fetch(
        `${Endpoints.getVenues}?page=${page}&size=${size}&query=${query}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      return error.message;
    }
  }

  async checkUser(id) {
    const requestOptions = {
      method: "GET",
      headers: reqHeaders,
    };
    try {
      const respJson = await fetch(
        `${Endpoints.checkUser}/${id}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      return error.message;
    }
  }

  async checkInstantUser(id) {
    const requestOptions = {
      method: "GET",
      headers: reqHeaders,
    };
    try {
      const respJson = await fetch(
        `${Endpoints.checkInstant}/${id}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      return error.message;
    }
  }

  async uploadImage(data) {
    const requestOptions = {
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify(data),
    };
    try {
      const respJson = await fetch(Endpoints.upload, requestOptions);
      return await respJson.json();
    } catch (error) {
      return error.message;
    }
  }

  async validate(data) {
    const requestOptions = {
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify(data),
    };
    try {
      const respJson = await fetch(Endpoints.validate, requestOptions);
      return await respJson.json();
    } catch (error) {
      return error.message;
    }
  }
}

export default new Api();
