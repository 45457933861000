import "./ClaimPrize.css";
import confettis from "../../assets/images/confettis.png";
import { useContext, useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandler } from "../../helpers/errorHelper";
import routeNames from "../../constants/routeNames";
import { LoadingContext } from "../../context/LoadingContext";
import { Retailers } from "../../constants/Retailers";
const ClaimPrize = () => {
  const [user, setUser] = useState();
  const [retailer, setRetailer] = useState(Retailers.GREENE_KING);
  const [hasError, setHasError] = useState(false);
  const { setLoading } = useContext(LoadingContext);

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    ApiService.checkUser(params.id).then((res) => {
      if (res && res?.result && res?.data) {
        if (res?.data?.winner && res?.data?.user) {
          setUser(res?.data?.user);
          setLoading(false);
        } else {
          errorHandler(res, navigate);
        }
      } else {
        errorHandler(res, navigate);
      }
    });
  }, []);

  const setRetailerDetails = (retailer) => {
    if (retailer) {
      switch (retailer) {
        case Retailers.GREENE_KING.title:
          setRetailer(Retailers.GREENE_KING);
          break;
        case Retailers.GREENE_KING_IW.title:
          setRetailer(Retailers.GREENE_KING);
          break;
        case Retailers.GREENE_KING_IFT.title:
          setRetailer(Retailers.GREENE_KING);
          break;
        case Retailers.GREENE_KING_MANAGED.title:
          setRetailer(Retailers.GREENE_KING);
          break;
        case Retailers.STONEGATE.title:
          setRetailer(Retailers.STONEGATE);
          break;
        case Retailers.STONEGATE_IW.title:
          setRetailer(Retailers.STONEGATE);
          break;
        case Retailers.STONEGATE_LT.title:
          setRetailer(Retailers.STONEGATE);
          break;
        case Retailers.STONEGATE_MANAGED.title:
          setRetailer(Retailers.STONEGATE);
          break;
        case Retailers.STAR_PUBS_AND_BARS.title:
          setRetailer(Retailers.STAR_PUBS_AND_BARS);
          break;
        case Retailers.STAR_PUBS_AND_BARS_IW.title:
          setRetailer(Retailers.STAR_PUBS_AND_BARS);
          break;
        case Retailers.STAR_PUBS_JAT.title:
          setRetailer(Retailers.STAR_PUBS_AND_BARS);
          break;
        case Retailers.OTHER.title:
          setRetailer(Retailers.GENERIC);
          break;
        case Retailers.OTHER_IW.title:
          setRetailer(Retailers.GENERIC);
          break;
        case Retailers.GENERIC.title:
          setRetailer(Retailers.GENERIC);
          break;
        default:
          setRetailer(Retailers.GENERIC);
          break;
      }
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    const data = { ...user };
    data[name] = value;
    setUser(data);
  };

  const validateForm = () => {
    if (
      !user?.phoneNumber ||
      !user?.addressLine1 ||
      !user.cityTown ||
      !user?.country ||
      !user?.postCode
    ) {
      setHasError(true);
      return false;
    }
    return true;
  };

  const submit = () => {
    const validationSucccess = validateForm();
    if (validationSucccess) {
      ApiService.claim(user).then((res) => {
        if (res && res?.result) {
          navigate(routeNames.CLAIMED, {
            state: {
              redirected: true,
            },
          });
        } else {
          errorHandler(res, navigate);
        }
      });
    } else {
      setHasError(true);
    }
  };
  return (
    <>
      <img
        src={confettis}
        className="absolute top-0 left-0  object-contain -z-1 opacity-50"
        alt="Confetti"
      />
      <div className="z-10 relative">
        <h1 className="page-title z-10">Congratulations</h1>
        <p className="description z-10 mb-6"> {"You're a winner"}</p>

        <div className="prize-box">
          <h3 className="prize-title">
            {user?.prize?.title ?? "VIP GRAND PRIX"}
          </h3>
          <p className="prize-description">{user?.prize?.description}</p>
        </div>
        {retailer?.claimText && (
          <p className="description !font-normal mb-6">{retailer?.claimText}</p>
        )}
        <p className="description !font-normal mb-6">
          Enter your details below to claim your prize
        </p>
        <img className="prize-image" src={user?.prize?.image} />

        <input
          type="text"
          readOnly
          className="form-input !text-lightGray !bg-[#EEEEEE] placeholder:!text-lightGray"
          defaultValue={user?.firstName}
        />
        <input
          type="text"
          readOnly
          className="form-input !text-lightGray !bg-[#EEEEEE] placeholder:!text-lightGray"
          defaultValue={user?.lastName}
        />
        <input
          type="text"
          placeholder="Address Line 1"
          name="addressLine1"
          onChange={onChange}
          className="form-input "
          defaultValue={user?.addressLine1}
        />
        {hasError && !user?.addressLine1 && (
          <p className="input-error">Enter your address</p>
        )}
        <input
          type="text"
          placeholder="Address Line 2"
          name="addressLine2"
          onChange={onChange}
          className="form-input "
          defaultValue={user?.addressLine2}
        />
        <input
          type="text"
          placeholder="Town/City"
          name="cityTown"
          onChange={onChange}
          className="form-input "
          defaultValue={user?.cityTown}
        />
        {hasError && !user?.cityTown && (
          <p className="input-error">Enter your town/city</p>
        )}
        <input
          type="text"
          placeholder="Postcode"
          name="postCode"
          onChange={onChange}
          className="form-input "
          defaultValue={user?.postCode}
        />
        {hasError && !user?.postCode && (
          <p className="input-error">Enter your postcode</p>
        )}
        <select
          name="country"
          className="form-input country-select"
          onChange={onChange}
        >
          <option value="">Country</option>
          <option value="England">England</option>
          <option value="Wales">Wales</option>
          <option value="Scotland">Scotland</option>
        </select>

        {hasError && !user?.country && (
          <p className="input-error">Select your country</p>
        )}

        <input
          type="number"
          placeholder="Mobile Number"
          onChange={onChange}
          className="form-input"
          name="phoneNumber"
          defaultValue={user?.phoneNumber}
        />
        {hasError && !user?.phoneNumber && (
          <p className="input-error">Enter your phone number</p>
        )}

        <div className="text-center mt-8">
          <button onClick={submit} className="heineken-btn">
            Claim your prize
          </button>
        </div>
      </div>
    </>
  );
};

export default ClaimPrize;
