import { useContext, useEffect } from "react";
import { LoadingContext } from "../../context/LoadingContext";

const CookiePolicy = () => {
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <div className="cookie-policy">
      <h1 className="page-title">Cookie Policy</h1>
      <p className="description !text-left !font-normal mb-3">
        <ol className="list-none" start="1">
          <li className="font-bold">1. WHAT THIS POLICY COVERS</li>
          <p className="mb-3">This Cookie Policy sets out:</p>
          <ul className="list-disc pl-8 mb-3">
            <li>what a cookie is (paragraph 2);</li>
            <li>an overview of the cookies we use (paragraph 3);</li>
            <li>
              how you can control cookies placed on your device (paragraph 4);
            </li>
            <li>
              a detailed table setting out all of the cookies that we use
              (paragraph 5); and
            </li>
            <li>concluding remarks (paragraph 6).</li>
          </ul>
          <li>
            <p className="description !text-left !font-normal mb-3">
              We use cookies to give you a better online experience. This
              website (“Website”) only uses cookies which are necessary for us
              to deliver the website to you. However, you should still read the
              following policy to ensure you understand how we use cookies.
              <br /> <br />
              In order to make full use of the Website, your computer, tablet or
              mobile phone will need to accept cookies. Accordingly, it is
              important that you know what cookies our Website uses and for what
              purposes.
            </p>
          </li>
          <li className="font-bold">2. WHAT IS A COOKIE?</li>
          <p className="description !text-left !font-normal mb-3">
            Cookies are small text files containing small amounts of information
            which are downloaded and may be stored on your user device e.g. your
            computer (or other internet enabled devices, such as a smartphone or
            tablet). We may use similar techniques, such as pixels, GIFs, web
            beacons, device fingerprints, etc. For the sake of consistency, all
            these techniques combined will hereafter be referred to as ‘cookies’
            in this Cookie Policy.
            <br />
            <br />
            This Cookie Policy provides you with information about the cookies
            we use and why. Sometimes a cookie ID or the information a cookie
            collects can be considered personal data. Our{" "}
            <a
              href="https://www.heineken.co.uk/legal-hub/heineken-brands-privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            sets out full details of the personal data we may collect and how we
            may use your personal information.
          </p>
          <li className="font-bold"> 3. COOKIES USED ON THE WEBSITE</li>
          <p className="description !text-left !font-normal mb-3">
            We use necessary cookies on our Website (these are set by default).
            Necessary cookies are essential and help you navigate the Website.
            They enhance the user friendliness of the Website. These cookies
            also help to support website security and basic functionality.
            <br />
            <br />
            Some of these are session cookies that are temporary and allow us to
            link your actions during a browser session. Session cookies are
            erased when you close the browser. Other cookies are persistent
            cookies, which remain on your device for the period of time
            specified in the cookie.
          </p>
          <li className="font-bold">4. CONTROL YOUR COOKIE SETTINGS</li>
          <p className="description !text-left !font-normal mb-3">
            You can manage your cookie settings in your browser at any time. If
            you simply disable all cookies in your browsers settings, you may
            find that certain sections or features of our Website will not work,
            because your browser may prevent us from setting functionally
            required cookies. The following links may assist you in managing
            your cookies settings, or you can use the 'Help' option in your
            internet browser for more details.
          </p>
          <ul className="list-disc pl-8 mb-3">
            <li>
              Internet Explorer/Microsoft Edge:{" "}
              <a
                href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                target="_blank"
                rel="noreferrer"
              >
                https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
              </a>
              <br />
              <a
                href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                target="_blank"
                rel="noreferrer"
              >
                https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd{" "}
              </a>
            </li>
            <li>
              Mozilla Firefox:{" "}
              <a
                href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectslug=Cookies&redirectlocale=en-US"
                target="_blank"
                rel="noreferrer"
              >
                https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectslug=Cookies&redirectlocale=en-US
              </a>
            </li>
            <li>
              Google Chrome:{" "}
              <a
                href="https://support.google.com/chrome/answer/95647?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                https://support.google.com/chrome/answer/95647?hl=en
              </a>
            </li>
            <li>
              Safari:{" "}
              <a
                href="https://support.apple.com/en-al/guide/safari/sfri11471/mac"
                target="_blank"
                rel="noreferrer"
              >
                https://support.apple.com/en-al/guide/safari/sfri11471/mac
              </a>
            </li>
            <li>
              Opera:{" "}
              <a
                href="https://help.opera.com/en/latest/web-preferences/"
                target="_blank"
                rel="noreferrer"
              >
                https://help.opera.com/en/latest/web-preferences/
              </a>
            </li>
          </ul>
          <li className="font-bold">5. COOKIE USE</li>
          <p className="description !text-left !font-normal mb-3">
            At present, no cookies are being dropped on this website.
          </p>
          <li className="font-bold">6. CONCLUDING REMARKS</li>
          <p className="description !text-left !font-normal mb-0">
            We will keep this Cookie Policy under review and may make updates to
            it from time to time. Any changes to this Cookie Policy will be
            posted on our Website, and to the extent reasonably possible, will
            be communicated to you. You can consult this web page for the latest
            version.
            <br /> <br />
            If you have any further questions and/or comments, please contact{" "}
            <a href="mailto:protectingyourdata@heineken.co.uk.">
              protectingyourdata@heineken.co.uk.
            </a>
            <br />
            <br />
            The most recent version of this Cookie Policy is dated January 2022.
          </p>
        </ol>
      </p>
    </div>
  );
};

export default CookiePolicy;
