import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routeNames from "../../constants/routeNames";
import moment from "moment";
import { LoadingContext } from "../../context/LoadingContext";

const PreEntry = () => {
  const [date, setDate] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (!location?.state?.redirected) {
      navigate(routeNames.LANDING);
    } else {
      setDate(location?.state?.data?.date);
      setLoading(false);
    }
  }, []);
  return (
    <div>
      <h1 className="page-title">Sorry</h1>
      <h2 className="description mb-6">This promotion hasn’t started yet.</h2>
      <p className="!font-normal description">
        Promotion starts {date && moment.utc(date).format("DD/MM/YY")}
      </p>
    </div>
  );
};

export default PreEntry;
