import { useContext, useEffect, useState } from "react";
import "./AgeGate.css";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingContext } from "../../context/LoadingContext";
import helper from "../../helpers/staticData";
import moment from "moment";
const AgeGate = () => {
  const [isAgeValid, setIsAgeValid] = useState(false);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useContext(LoadingContext);
  const [dateOfBirth, setDateOfBirth] = useState({
    day: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    if (isAgeValid) {
      localStorage.setItem("heineken-150-age-gate", true);
      setLoading(true);
      navigate(location?.state?.prevPath ? location?.state?.prevPath : "/", {
        state: location?.prevState ? location?.prevState : {},
      });
    } else {
      setLoading(false);
    }
  }, [isAgeValid]);

  const checkAge = () => {
    const data = { ...dateOfBirth };
    if (!data.day || !data.month || !data.year) {
      setHasError(true);
    } else {
      const momentDate = moment(
        `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}`
      );
      const momentNow = moment();
      if (momentNow.diff(momentDate, "years") >= 18) {
        setIsAgeValid(true);
      } else {
        setHasError(true);
      }
    }
  };

  return (
    <div className="age-gate">
      <h1 className="page-title">Please enter your date of birth</h1>
      <div className="space-x-2 flex flex-row">
        <select
          name="day"
          value={dateOfBirth.day}
          onChange={(e) => {
            setHasError(false);
            setDateOfBirth({ ...dateOfBirth, day: e.target.value });
          }}
          className=" age-input-select"
        >
          <option value="">DD</option>
          {helper.dayOptions()}
        </select>
        <select
          name="month"
          value={dateOfBirth.month}
          onChange={(e) => {
            setHasError(false);

            setDateOfBirth({ ...dateOfBirth, month: e.target.value });
          }}
          className=" age-input-select"
        >
          <option value="">MM</option>
          {helper.monthOptions()}
        </select>
        <select
          name="year"
          value={dateOfBirth.year}
          onChange={(e) => {
            setHasError(false);
            setDateOfBirth({ ...dateOfBirth, year: e.target.value });
          }}
          className="age-input-select"
        >
          <option value="">YYYY</option>
          {helper.yearOptions()}
        </select>
      </div>
      {hasError &&
        (!dateOfBirth.day || !dateOfBirth.month || !dateOfBirth.year) && (
          <p className="input-error !mt-2 text-left ">
            Please select a valid date
          </p>
        )}
      {hasError && dateOfBirth.day && dateOfBirth.month && dateOfBirth.year && (
        <p className="input-error !mt-2 text-center">
          You must be 18 or older to enter{" "}
        </p>
      )}
      <div className="text-center mt-8">
        <button className="heineken-btn" onClick={checkAge}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default AgeGate;
