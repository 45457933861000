import ErrorTypes from "../constants/ErrorTypes";
import routeNames from "../constants/routeNames";

export const errorHandler = (response, navigate) => {
  const error = response?.error;
  switch (error?.type) {
    case ErrorTypes.ENTRY_NOT_EXISTS:
      navigate(routeNames.LANDING, {
        state: {
          redirected: true,
        },
      });
      break;
    case ErrorTypes.ALREADY_ENTERED:
      navigate(routeNames.ALREADY_ENTERED, {
        state: {
          redirected: true,
          data: response?.data,
        },
      });
      break;
    case ErrorTypes.ALREADY_CLAIMED:
      navigate(routeNames.ALREADY_CLAIMED, {
        state: {
          redirected: true,
        },
      });
      break;
    case ErrorTypes.NOT_WINNER:
      navigate(routeNames.NOT_WINNER, {
        state: {
          redirected: true,
        },
      });
      break;
    case ErrorTypes.CAMPAIGN_CLOSED:
      navigate(routeNames.CLOSED, {
        state: {
          redirected: true,
          data: response?.data,
        },
      });
      break;
    case ErrorTypes.CAMPAIGN_OUT_HOURS:
      navigate(routeNames.OUTHOURS, {
        state: {
          redirected: true,
          data: response?.data,
        },
      });
      break;
    case ErrorTypes.LINK_EXPIRED:
      navigate(routeNames.LINK_EXPIRED, {
        state: {
          redirected: true,
          data: response?.data,
        },
      });
      break;
    case ErrorTypes.CAMPAIGN_PRE_ENTRY:
      navigate(routeNames.PREENTRY, {
        state: {
          redirected: true,
          data: response?.data,
        },
      });
      break;
    default:
      navigate(routeNames.LANDING);
      break;
  }
};
