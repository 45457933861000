import { useContext, useEffect } from "react";
import { LoadingContext } from "../../context/LoadingContext";
import { useLocation } from "react-router-dom";

const AlreadyClaimed = () => {
  const { setLoading } = useContext(LoadingContext);
  const location = useLocation();

  useEffect(() => {
    if (!location?.state?.redirected) {
      window.location.replace("/");
    }
    setLoading(false);
  }, []);

  return (
    <div>
      <h1 className="page-title">Sorry</h1>
      <p className="description">
        It looks like you’ve already claimed this prize.
      </p>
    </div>
  );
};

export default AlreadyClaimed;
