import React, { useEffect, useState } from "react";

const CookieBar = () => {
  const [cookies, setCookies] = useState(true);

  const acceptCookies = () => {
    let gdprCookie = localStorage.setItem(
      "HEINEKEN_150_ACCEPTED_COOKIES",
      new Date()
    );
    setCookies(gdprCookie);
  };

  const checkCookies = () => {
    return localStorage.getItem("HEINEKEN_150_ACCEPTED_COOKIES");
  };

  useEffect(() => {
    let gdprCookie = checkCookies();
    setCookies(gdprCookie);
  }, [cookies]);

  return cookies ? (
    ""
  ) : (
    <div className="z-[2000] flex p-4 fixed bottom-0 left-0 bg-darkGreen min-h-[5vh] w-screen">
      <p className="leading-8 text-white">
        We use cookie technologies on this site as described in our{" "}
        <a href="/cookie-policy" target="_blank" rel="noreferrer">
          Cookie Policy
        </a>
        . The cookies we use are essential for the website to function - they
        support website security, help you navigate the website and enhance its
        user friendliness.
      </p>
      <span
        className="ml-auto text-center flex pt-2 lg:pt-0 lg:items-center"
        onClick={acceptCookies}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="29.2571"
            width="3.87898"
            height="41.3758"
            transform="rotate(45 29.2571 0)"
            fill="#fff"
          />
          <rect
            x="32"
            y="29.2572"
            width="3.87898"
            height="41.3758"
            transform="rotate(135 32 29.2572)"
            fill="#fff"
          />
        </svg>{" "}
      </span>
    </div>
  );
};

export default CookieBar;
