import drinkAware from "../../assets/images/drinkAware.png";
import heinekenResponsibly from "../../assets/images/heinekenResponsibly.png";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-images">
        <img
          src={drinkAware}
          className="w-[161px] mt-2 mb-4 lg:mb-0 lg:mt-0 "
        />
        <img
          src={heinekenResponsibly}
          className="w-[200px] mt-2 lg:mt-0  lg:mb-0 lg:ml-auto"
        />
      </div>
      <div className="footer-images !mb-0">
        <p className="footer-links font-futura">
          <a
            href="https://www.heineken.co.uk/legal-hub/heineken-brands-privacy-policy"
            rel="noreferrer"
            className="font-futura"
            target="_blank"
          >
            Privacy Policy
          </a>{" "}
          |{" "}
          <a href="/terms" target="_blank" className="font-futura">
            Terms and Conditions
          </a>{" "}
          |{" "}
          <a href="/cookie-policy" target="_blank" className="font-futura">
            Cookie Policy
          </a>{" "}
        </p>
        <p className="footer-copyright font-futura">
          All rights reserved. “Heineken®” is a registered trademark. Heineken®
          0.0 contains no more than 0.05% alcohol.
        </p>
      </div>
    </div>
  );
};

export default Footer;
