import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const AgeGateRoute = () => {
  const location = useLocation();
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);
  return localStorage.getItem("heineken-150-age-gate") ? (
    <Outlet />
  ) : (
    <Navigate
      to="/age-gate"
      replace={true}
      state={{
        prevPath: location?.pathname,
        prevState: location?.state,
      }}
    />
  );
};

export default AgeGateRoute;
