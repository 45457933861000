import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routeNames from "../../constants/routeNames";
import moment from "moment";
import { LoadingContext } from "../../context/LoadingContext";

const OutOfHours = () => {
  const [startHour, setStartHour] = useState();
  const [endHour, setEndHour] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (!location?.state?.redirected) {
      navigate(routeNames.LANDING);
    } else {
      const sHour = new Date();
      const eHour = new Date();
      sHour.setUTCHours(location?.state?.data?.startHour, 0, 0, 0);
      eHour.setUTCHours(location?.state?.data?.endHour, 0, 0, 0);
      setStartHour(sHour);
      setEndHour(eHour);
      setLoading(false);
    }
  }, []);

  return (
    <div>
      <h1 className="page-title">Sorry</h1>
      <p className="description mb-6 !font-normal">
        This promotion is only open between{" "}
        {startHour && moment.utc(startHour).format("ha")} and{" "}
        {endHour && moment.utc(endHour).format("ha")}.
      </p>
      <p className="description !font-normal">
        Please check back again during these hours.
      </p>
    </div>
  );
};

export default OutOfHours;
