import { useContext, useEffect, useState } from "react";
import scanIcon from "../../assets/images/scanIcon.svg";
import loadingIcon from "../../assets/images/loading.gif";
import "./Scan.css";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "../../services/ApiService";
import { errorHandler } from "../../helpers/errorHelper";
import ErrorTypes from "../../constants/ErrorTypes";
import { LoadingContext } from "../../context/LoadingContext";
const Scan = () => {
  const params = useParams();
  const [image, setImage] = useState(scanIcon);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [showUploadBox, setShowUploadBox] = useState(true);
  const [recognition, setRecognition] = useState(true);
  const [checkWinner, setCheckWinner] = useState(false);
  const [title, setTitle] = useState("Photograph your Heineken® now");
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    ApiService.checkUser(params.id).then((res) => {
      if (res && res?.result && res?.data) {
        if (res?.data?.winner && res?.data?.user) {
          navigate(`/claim-prize/${params.id}`, {
            state: {
              user: res?.data?.user,
              redirected: true,
            },
          });
        }
        setUser(res?.data);
        setLoading(false);
      } else {
        errorHandler(res, navigate);
      }
    });
  }, []);

  const removeUploaded = () => {
    setImage(scanIcon);
    setShowUploadBox(true);
    setTitle("Photograph your Heineken® now");
  };

  const submitForm = async () => {
    setCheckWinner(true);
    setTitle("");

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 4000); // 4 seconds delay
    });

    const data = { ...user };
    data.url = image;
    setSubmitLoading(true);
    const response = await ApiService.validate(data);
    setSubmitLoading(false);
    if (response && response?.result && response?.data) {
      navigate(`/claim-prize/${params.id}`, {
        state: {
          prize: response?.data?.prize,
          redirected: true,
        },
      });
    } else if (response?.error) {
      if (response?.error?.type === ErrorTypes.RECOGNITION_FAILED) {
        setTitle("Sorry");
        setRecognition(false);
        setShowUploadBox(true);
        setCheckWinner(false);
      } else {
        errorHandler(response, navigate);
      }
    }
  };

  const onFileUpload = (e) => {
    setUploadLoading(true);
    setTitle("Upload in progress...");
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const fileObject = {
        name: file.name,
        mimeType: file.type,
        fileContent: reader.result.split(",")[1],
      };
      const response = await ApiService.uploadImage(fileObject);
      if (response && response?.data) {
        setImage(response?.data);
        setShowUploadBox(false);
        setUploadLoading(false);
        setTitle("Confirmation");
      }
    };
  };

  return (
    <div>
      {title && <h1 className="page-title">{title}</h1>}
      {title === "Upload in progress..." && (
        <div className="loading-spinner w-full mx-auto" />
      )}

      {!checkWinner && showUploadBox && !uploadLoading && recognition && (
        <>
          <p className="description !font-normal mb-6">
            Simply take a picture of your Heineken® bottle or pint to find out
            if{" you're"} a winner.
          </p>
          <label className="w-full ">
            <div className="upload-box">
              <input
                type="file"
                hidden
                name="image"
                accept=".jpg,.jpeg,.png,.bmp"
                onChange={onFileUpload}
              />
              <img src={scanIcon} alt="upload" className=" p-10" />
            </div>
          </label>
        </>
      )}
      {!checkWinner && !showUploadBox && image && (
        <>
          <h2 className="description !font-normal ">
            Your image has successfully uploaded.
          </h2>
          <p className="description !font-normal">
            Please click below to continue.
          </p>
          <div className="text-center uploaded mt-6">
            <input
              type={"button"}
              alt="uploaded"
              className="remove-icon"
              onClick={removeUploaded}
            />
            <img src={image} alt="uploaded" className="w-100 uploaded-image" />
          </div>
          <div className="text-center">
            <button
              className="cc-button heineken-btn"
              disabled={submitLoading}
              onClick={submitForm}
            >
              Submit
            </button>
          </div>
        </>
      )}

      {!checkWinner && showUploadBox && !uploadLoading && !recognition && (
        <>
          <p className="description mb-4 !font-normal">
            That didn’t seem to work. Please click the camera icon below to try
            again.
          </p>
          <p className="description mb-6 !font-normal">
            If you're having trouble scanning, please take a picture of your
            drink alongside the Heineken 150 Years poster or coaster.
          </p>
          <label className="w-100">
            <div className="upload-box">
              <input
                type="file"
                hidden
                name="image"
                accept=".jpg,.jpeg,.png,.bmp"
                onChange={onFileUpload}
              />
              <img src={scanIcon} alt="upload" className="upload-icon p-10" />
            </div>
          </label>
        </>
      )}

      {checkWinner && (
        <div className="w-full mx-auto">
          <img
            src={loadingIcon}
            alt="loading"
            className="loading-icon mx-auto"
          />
        </div>
      )}
    </div>
  );
};

export default Scan;
